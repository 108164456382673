export const getEnumComparators = () => 
{
    return [
        {displayKey: "=", displayName: 'Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue == filteredValue}, 
        {displayKey: "!=", displayName: 'Not Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue != filteredValue}
    ]
}
export const getNumComparators = () => 
{
    return [
        {displayKey: "=", displayName: 'Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue == filteredValue}, 
        {displayKey: "!=", displayName: 'Not Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue != filteredValue}, 
        {displayKey: ">", displayName: 'Greater Than', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue > filteredValue}, 
        {displayKey: ">=", displayName: 'Greater Than or Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue >= filteredValue},
        {displayKey: "<", displayName: 'Less Than', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue < filteredValue},
        {displayKey: "<=", displayName: 'Less Than or Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue <= filteredValue}
    ];
}

export const getTextComparators = () =>
{
    return [
        {displayKey: "=", displayName: 'Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue == filteredValue}, 
        {displayKey: "!=", displayName: 'Not Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue != filteredValue}, 
        {displayKey: ">", displayName: 'Greater Than', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue > filteredValue}, 
        {displayKey: ">=", displayName: 'Greater Than or Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue >= filteredValue},
        {displayKey: "<", displayName: 'Less Than', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue < filteredValue},
        {displayKey: "<=", displayName: 'Less Than or Equals', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue <= filteredValue},
        {displayKey: "~", displayName: 'Contains', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue.includes(filteredValue)}
    ];
}

export const getDateComparators = () =>
{
    return [
        {displayKey: "=", displayName: 'On', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue == filteredValue},
        {displayKey: "!=", displayName: 'Not On', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue != filteredValue},
        {displayKey: ">", displayName: 'Before', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue > filteredValue},
        {displayKey: "<", displayName: 'After', predicate:([filteredValue], cellValue) => cellValue ==null || cellValue < filteredValue},
    ]
}