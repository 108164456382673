import React, { useState, useMemo, useCallback, useRef } from 'react'; // React State Management
import { useParams } from 'react-router';
import {  Container, Table, Dropdown, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import { getJob, getJobFiles } from '../apis/jobApi';
import { useQuery } from '@tanstack/react-query'

import { AddFileTable } from '../components/Files/AddFileTable';
import { JobHistory } from '../components/Jobs/History/JobHistory';
import { JobProgress } from '../components/Jobs/JobProgress';
import { JobTasks } from '../components/Jobs/JobTasks';
import { JobAccessModal } from '../components/Jobs/JobAccessModal';
import { dateTimeFormatter, durationFormatter } from '../library/formatters';

export const JobPage = () => 
{
    const [openAccess, setOpenAccess] = useState(false);
    const [activeTab, setActiveTab] = useState('tasks');

    const { id } = useParams();
    const columns = { sm: 1, md: 1, lg: 1};
    const { isLoading, isError, data } = useQuery({
        queryKey: [`job`, id],
        queryFn: async () => await getJob(id),
        keepPreviousData : true
    });
    
    const defaultPageSize = 25;

    const getInputFiles = async (params) => {   
        
        const response = await getJobFiles(id, 'Input', params.filterModel, params.sortModel, params.startRow, params.endRow-params.startRow)
        .then(response => {
            return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = response.total;
        params.successCallback(response.data, lastRow);
    }

    const getOutputFiles = async (params) => {
        const response = await getJobFiles(id, 'Output', params.filterModel, params.sortModel, params.startRow, params.endRow-params.startRow)
            .then(response => {
                return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = response.total;
        params.successCallback(response.data, lastRow);
    }

    const fetchDataForTab = async (tab, params) => {
        // Fetch data based on the active tab and the requested rows
        if (tab === 'inputs') {
            await getInputFiles(params);
        } else if (tab === 'outputs') {
            getOutputFiles(params);
        }
    };

    const getTabData = async (params) => 
    {
        await fetchDataForTab(activeTab, params);   
    }

    return(
        <>
        {
            !isLoading && !isError && 
            <>
                <h3 className="display-4">Job {id}</h3>
                <Container fluid className='p-1'>
                    <Row xs={12} sm={12} md={12} lg={12}>
                        <Col xs={{offset:10, span:2}} sm={{offset:10, span:2}} md={{offset:11, span:1}} lg={{offset:11, span:1}}>
                            <div className="right-button-container">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className='button-table'>
                                        <i class="bi bi-three-dots-vertical" aria-hidden="true"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu role="menu">
                                        <Dropdown.Item onClick={() => setOpenAccess(true)} role="menuitem">Edit Access</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>            
                <JobAccessModal show={openAccess} setShow={setOpenAccess}/>
                <Container fluid className="p-5 bg-light">
                    <Row>
                        <Col xs={5}>
                            <Container>
                                <Row>
                                    <Col><strong className="d-flex align-items-center">Description</strong></Col>
                                    <Col xs={7}><div style={{textAlign:"left"}}>{data.description}</div></Col>                                    
                                </Row>
                                <Row>
                                    <Col><strong className='d--flex align-items-center'>State</strong></Col>
                                    <Col xs={7}>{data.state}</Col>
                                </Row>
                                <Row>
                                    <Col><strong className="d-flex align-items-center">Created</strong></Col>
                                    <Col xs={7}>{dateTimeFormatter({ value: data.created })}</Col>
                                </Row>
                                <Row>
                                    <Col><strong className="d-flex align-items-center">Average Task Duration</strong></Col>
                                    <Col xs={7}>{durationFormatter(data.taskSummary.averageDuration)}</Col>
                                </Row>
                                <Row>

                                    <Col><strong className="d-flex align-items-center">Total Job Duration</strong></Col>
                                    <Col xs={7}>{durationFormatter(data.taskSummary.totalDuration)}</Col>
                                </Row>
                                <Row>
                                    <Col><strong className="d-flex align-items-center">Total Number of Tasks</strong></Col>
                                    <Col xs={7}>{data.taskSummary.total ? data.taskSummary.total : 0}</Col>
                                </Row>
                            </Container>
                            {/* <Table>
                        
                                </tbody>
                            </Table> */}
                        </Col>
                        <Col>
                            <Container>
                                <Row>
                                {
                                    data.taskGenerationEntryPoint?
                                        <Col><div className="d-flex align-items-center"><strong className="me-2">Task Generation Entrypoint</strong> {data.taskGenerationEntryPoint?.file?.fileName}/{data.taskGenerationEntryPoint?.method}</div></Col>:""
                                    }
                                </Row>
                                <Row>
                                    {
                                    data.taskExecutionEntryPoint?
                                        <div className="d-flex align-items-center"><strong className="me-2">Task Execution Entrypoint</strong> {data.taskExecutionEntryPoint?.file?.fileName} / {data.taskExecutionEntryPoint?.method}</div>:""
                                    }
                                </Row>
                                <Row>
                                    {
                                    data.resultGatheringEntryPoint?
                                        <div className="d-flex align-items-center"><strong className="me-2">Result Gathering Entrypoint</strong> {data.resultGatheringEntryPoint?.file?.fileName} / {data.resultGatheringEntryPoint?.method}</div>:""
                                    }
                                </Row>
                            
                            </Container>
                                
                        </Col>
                        <Col>
                            <Row>
                                <Accordion className="bg-light">
                                    <Accordion.Item eventKey="0" className="bg-light">
                                        <Accordion.Header className="bg-light">Metadata</Accordion.Header>
                                        <Accordion.Body className="bg-light">
                                            <strong>Job Application:</strong> 0317
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Progress</Accordion.Header>
                                        <Accordion.Body>
                                            <JobProgress jobData={data}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Tabs
                    id="job-data-tabs"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3">
                    <Tab eventKey={"tasks"} title="Tasks">
                        <Container fluid>
                            
                            <JobTasks jobId={id} active = {activeTab === 'tasks'}/>
                            
                        </Container>
                    </Tab>
                    <Tab eventKey={"inputs"} title="Input Files">
                        <Container fluid>
                            <AddFileTable tableSize={'half-page-table'} fileTableId={"input-files"} getRows={getInputFiles} additionalColumns={[]} defaultPageSize={defaultPageSize} addButtonConfiguration= {{disableAdd: true, hideAdd: false}} active={activeTab==='inputs'}/>
                        </Container>
                    </Tab>
                    <Tab eventKey={"outputs"} title="Output Files">
                        <Container fluid>
                            <AddFileTable tableSize={'half-page-table'} fileTableId={"output-files"} getRows={getOutputFiles} additionalColumns={[]} defaultPageSize={defaultPageSize}addButtonConfiguration={{disableAdd: true, hideAdd: true}} active={activeTab==='outputs'}/>
                        </Container>
                    </Tab>
                    <Tab eventKey={"history"} title="History">
                        <Container fluid>                    
                            <Row>
                                <JobHistory jobId={id} active={activeTab==="history"}/>
                            </Row>
                        </Container>
                    </Tab>
                </Tabs>
            </>
        }
        </>
    )
}