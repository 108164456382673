import React from 'react'; // React State Management
import { getDateComparators, getEnumComparators } from '../library/comparators';

import { AddFileTable } from '../components/Files/AddFileTable';
import { getFiles } from '../apis/fileApi';
import { dateTimeFormatter } from '../library/formatters';


import { Container, Row, Col } from 'react-bootstrap';

export const FilesPage = () => 
{
    const dateComparators = getDateComparators();
    const enumComparators = getEnumComparators();
    const addButtonConfiguration = {
        disableAdd: false,
        hideAdd: false,
    }
    const additionalColumns = [
        {field: "created", filter: 'agDateColumnFilter', filterParams:{filterOptions : dateComparators, maxNumConditions: 1, maxValidYear:9999}, valueFormatter: dateTimeFormatter},
        {field: "type", sortable: false, filterParams:{filterOptions : enumComparators, maxNumConditions: 1}},
    ];

    // const columnConfiguration = {sm: 4, md: 8, lg: 16};
    const columnConfiguration = { xs:4, sm: 4, md: 4, lg: 4};
    const getRows = async (params) => {
        const filters = params.filterModel;
        const response = await getFiles(filters, params.sortModel, params.startRow, params.endRow - params.startRow)
        .then(response => {
            return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        
        });
        // if on or after the last page, work out the last row.
        let lastRow = response.total;
        params.successCallback(response.data, lastRow);
    }

    const addFiles = async (files) => {    
    }

    return <>
        <Container>
            <Row xs={12} sm={12} md={12} lg={12}>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <h3 class="display-4">Files</h3>
                </Col>
                <AddFileTable fileTableId={"file-page"} getRows={getRows} additionalColumns={additionalColumns} addFiles={addFiles} addButtonConfiguration={addButtonConfiguration} columnConfiguration={columnConfiguration}/>
            </Row>
        </Container>
    </>
}