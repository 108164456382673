import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { getTextComparators } from '../../../library/comparators';
import { dateTimeFormatter } from '../../../library/formatters';
import { getJobHistory } from '../../../apis/jobApi';
import { BaseJobHistoryRow, JobHistoryOperation } from './JobHistoryOperation'
import { JobHistoryDetails } from './JobHistoryDetails';
import { Table, Row, Col } from 'react-bootstrap';
export const JobHistory = ({jobId}) => {

    const [jobHistoryData, setJobHistoryData] = useState();
    const gridRef = useRef();
    const pageSizeOptions = [15, 25, 50, 100];
    const defaultPageSize = 25;
    
    const getHistory = async (params) => {
        const response = await getJobHistory(jobId)
            .then(response => {
                return {total: response.length, data: response.map((fileRow => {
                    const editedOn = fileRow.values.map(value => value.key).join(', ');
                    return {application: fileRow.application, created: fileRow.created, operationSummary: `${fileRow.operation} ${editedOn}`, additionalData: fileRow.values, operation : fileRow.operation, user:fileRow.user }
                }))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }
        params.successCallback(response.data, lastRow);
    }

    const onGridReady = useCallback( async (params) => {
        const dataSource = {
          rowCount: undefined,
          getRows: getHistory
        };
        params.api.setGridOption('datasource', dataSource);
    }, []);

    const textComparators = getTextComparators(); 
    const [colDefs, setColDefs] = useState([
        {field: "created", headerName: "Created", valueFormatter: dateTimeFormatter, filter:false, sortable: false},
        {field: "operationSummary", headerName: "Operation Summary",filter: 'agTextColumnFilter', filterParams:{filterOptions : textComparators, maxNumConditions: 1},flex:1.2, cellRenderer:BaseJobHistoryRow, sortable: false},
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
        flex: 1
        };
    }, []);
  
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }
    , []);

    const initialState =  useMemo(() => {
        return {
            rowSelection:["1"],
        };
    }, []);

    const onRowClicked = useCallback(async(event) => {
        setJobHistoryData(event.data);
    },[])
    return <>
    
        <Table>
            <Row>
                <Col>
                    <div className="ag-theme-quartz half-page-table table-align">
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            suppressClickEdit={true}
                            ref = {gridRef}
                            pagination={true}
                            columnDefs={colDefs}
                            rowModelType={'infinite'}
                            rowSelection={rowSelection}
                            initialState={initialState}
                            onRowClicked={onRowClicked}
                            paginationPageSizeSelector={pageSizeOptions}
                            paginationPageSize={defaultPageSize}
                            onGridReady={onGridReady}
                        />
                    </div>
                </Col>
                <Col>
                    <JobHistoryDetails jobHistoryData={jobHistoryData}/>
                </Col>
            </Row>
        </Table>
    </>
};

