import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { UpdateService } from '../../services/UpdateService';
import { dateTimeFormatter } from './../../library/formatters';
import { getJobTasks } from '../../apis/taskApi';
import { CustomStateFilter } from '../Table/CustomFilters/CustomStateFilter';
import { getNumComparators, getTextComparators, getDateComparators } from '../../library/comparators';
export const JobTasks = ({jobId, active}) => {

    const numComparators = getNumComparators();
    const textComparators = getTextComparators();
    const dateComparators = getDateComparators();

    const getTasks = async (params) => {
        const filters = params.filterModel;
        const sorting = params.sortModel;
        const response = await getJobTasks(jobId, filters, sorting, params.startRow, params.endRow-params.startRow)
            .then(response => {
                return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                 
        // if on or after the last page, work out the last row.
        let lastRow = response.total;
        params.successCallback(response.data, lastRow);
    }
    const gridRef = useRef();
    const defaultPageSize = 25;
    const onGridReady = useCallback( async (params) => {
        const dataSource = {
          rowCount: undefined,
          getRows: getTasks
        };
        params.api.setGridOption('datasource', dataSource);
    });

    const pageSizeOptions=[15, 25, 50, 100]

    const refreshTable = () => {
        gridRef.current.api.refreshInfiniteCache();
    }

    
    const [colDefs, setColDefs] = useState([
        {field: "index", headerName: "Task Id", filter: 'agNumberColumnFilter', flex:1, filterParams :{filterOptions: numComparators, maxNumConditions: 1}},
        {field: "state", headerName: "Task State", filter: 'agTextColumnFilter', filter: CustomStateFilter , flex:1, filterParams: {title: "State Filter", filterOptions:['Completed', 'Failed', 'Generated', 'Started']}},
        {field: "created", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1, filterParams:{filterOptions : dateComparators, maxNumConditions: 1, maxValidYear:9999}},
        {field: "started", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1, filterParams:{filterOptions : dateComparators, maxNumConditions: 1, maxValidYear:9999}},
        {field: "completed", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1, filterParams:{filterOptions : dateComparators, maxNumConditions: 1, maxValidYear:9999}}, //LS the text comparator isn't going to work because it has contains
        {field: "retryAttempts", headerName: "Retry Attempts", filter: 'agNumberColumnFilter', flex:1, filterParams:{filterOptions : numComparators, maxNumConditions: 1}},
    ]);

    const defaultColDef = useMemo(() => {
        return {
          flex: 1
        };
    }, []);

    return(
    <>
        <div className='ag-theme-quartz half-page-table table-align'>
            <AgGridReact
                suppressClickEdit={true}
                ref = {gridRef}
                pagination={true}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableClickSelection={false}
                rowModelType={'infinite'}
                onGridReady={onGridReady}
                paginationPageSize={defaultPageSize}
                paginationPageSizeSelector={pageSizeOptions}
                acheOverflowSize={2}
                maxConcurrentRequests={2}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
            />
        </div>
        <UpdateService updateDataFunction={refreshTable} gridRef={gridRef} active={active}/> 
    </>
    )
}