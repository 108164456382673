import { handleJsonResponse, getFilterQuery, addSortingQuery } from "./api";
import config from '../config';
import { getUser } from '../auth';


export async function getJobTasks(id, filters, sorting, start = 0, limit = 10){
  const user = getUser();
  const token = user?.access_token;
  
  var filterQuery = getFilterQuery(filters);
  filterQuery = addSortingQuery(filterQuery, sorting);
  
  const response = await fetch(`${config.API_URL}/jobs/${id}/tasks?start=${start}&limit=${limit}${filterQuery}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  
  return handleJsonResponse(response);
}