import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from 'ag-grid-react';

import { getJobs } from "../apis/jobApi";
import { getNumComparators, getEnumComparators, getTextComparators, getDateComparators } from "../library/comparators";
import { IdTableCellLink } from "../components/Table/IdTableCellLink";
import { DurationCell } from "../components/Jobs/DurationCell";
import { JobActions } from "../components/Jobs/JobActions";
import { SubmitJobModal } from '../components/Jobs/SubmitJobModal';
import { BatchActionAccordion } from '../components/BatchActionAccordion';
import { BatchJobActions } from "../components/Jobs/BatchJobActions";
import { dateFormatter } from "../library/formatters";

import { Row, Col, Button } from 'react-bootstrap'; // Bootstrap
import { UpdateService } from "../services/UpdateService";
import { CustomStateFilter } from "../components/Table/CustomFilters/CustomStateFilter";

export const JobsPage = () => {
  const gridRef = useRef();
  const [gridKey, setGridKey] = useState('grid-key-${Math.random()}');
  const pageSizeOptions = [15, 25, 50, 100];
  const defaultPageSize = 25;
  const [open, setOpen] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);   
  const numComparators = getNumComparators(); 
  const textComparators = getTextComparators(); 
  const enumComparators = getEnumComparators();
  const dateComparators = getDateComparators();
  
  const [colDefs, setColDefs] = useState([
    {field: "id", checkboxSelection: true, headerName: "Job Id", filter: false, sortable: false, cellRenderer: IdTableCellLink, cellRendererParams: { gridRef: gridRef, path: "/jobs/"}, flex:1},
    {field: "description", filter: 'agTextColumnFilter', sortable: true, filterParams:{filterOptions : textComparators, maxNumConditions: 1},flex:1.2},
    // {field: "state",filter: 'agTextColumnFilter', sortable: false, filterParams:{filterOptions : enumComparators, maxNumConditions: 1},flex:1},
    {field: "state", headerName: "State", filter: CustomStateFilter, sortable: false,  filterParams: {title: "State Filter", filterOptions:['Completed', 'Draft', 'Failed', 'Paused', 'Queued']}},
    {field: "priority",filter: 'agNumberColumnFilter', sortable: true, filterParams:{filterOptions : numComparators, maxNumConditions: 1},flex:1},
    {field: "created", filter: 'agDateColumnFilter', 
      sortable: true, valueFormatter: dateFormatter, 
      filterParams:{filterOptions : dateComparators, maxNumConditions: 1, maxValidYear:9999},flex:1, 
    },
    {field: "duration", headerName: "Duration", sortable: false, filter: false, valueFormatter: dateFormatter, cellRenderer:DurationCell,flex:1},
    {field: "actions", editable: false, filter: false, sortable: false, headerName: "Actions", cellRenderer: JobActions, cellRendererParams: { gridRef: gridRef},flex:1.1}
  ]);
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1
    };
  }, []);

  const onGridReady = useCallback( async (params) => {
    const dataSource = {
      getRows: async function (params) {
        const filters = params.filterModel;
        const response = await getJobs(filters, params.sortModel, params.startRow, params.endRow - params.startRow)
          .then(response => {
              return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
          });
        // if on or after the last page, work out the last row.
        let lastRow = response.total;
        params.successCallback(response.data, lastRow);
      }
    };
    params.api.setGridOption('datasource', dataSource);
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length > 0)
    {
      setOpen(true);
    }
    else
    {
      setOpen(false);
    }
  }, []);

  const openJobModal = () => {
    setShowJobModal(true);
  }

  const handleRefreshJobs = () => {
    gridRef.current.api.refreshInfiniteCache();
  }
  return (<>
    <Row>
      <Col>
          <h3 className="display-4">Jobs</h3>
      </Col>
      <Col className="d-flex justify-content-end align-items-end">
          <BatchActionAccordion open={open} batchAction={<BatchJobActions gridRef={gridRef} setOpen={setOpen} setGridKey={setGridKey}/>}/>
          {/* <Button className={"button-table"} onClick={openJobModal}>Add Job</Button> */}
          <SubmitJobModal show={showJobModal} setShow={setShowJobModal}/>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className={"ag-theme-quartz"}>
          <AgGridReact
            suppressClickEdit={true}
            ref={gridRef}
            key={gridKey}
            pagination={true}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            rowModelType={'infinite'}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            paginationPageSize={defaultPageSize}
            cacheBlockSize={defaultPageSize}
            paginationPageSizeSelector={pageSizeOptions}
            rowMultiSelectWithClick={true}
            cacheOverflowSize={2}
            maxConcurrentRequests={2}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
          />
        </div>
      </Col>
    </Row>
    <UpdateService updateDataFunction={handleRefreshJobs} active={true}/>      
  </>
  );
};
  