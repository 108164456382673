import { Col, Container, Row, Table } from "react-bootstrap"
import { dateTimeFormatter } from "../../../library/formatters";
import { getFilesById } from "../../../apis/fileApi";
import { useState, useEffect } from "react";

export const JobHistoryDetails = (data)=> {
    const [historyDetails, setHistoryDetails] = useState(null);
    const [fileHistoryDetails, setFileHistoryDetails] = useState(null);
    
    useEffect(() => {
        async function getData(){
            if(data === undefined || data.jobHistoryData === undefined)
            {
                setHistoryDetails(null)
            }
            else
            {
                const jobHistoryData = data.jobHistoryData;
                const operationSummary = jobHistoryData.operationSummary;
                setHistoryDetails(data)
                if (operationSummary.includes("Add"))
                {
                    setFileHistoryDetails(null)
                }
                else if(operationSummary.includes("Edit Files"))
                {
                    const diffValues = jobHistoryData.additionalData.map((data) => 
                    {
                        const {key, newValue, oldValue} = data;
                        const newValueArray = newValue.split(',');
                        const oldValueArray = oldValue ? oldValue.split(',') : [];
                        const diffArray = newValueArray.filter(value => !oldValueArray.includes(value));          
                        return {diff: diffArray};
                    });
                    const fileDetailsResult = getFilesById(diffValues[0].diff);
                    setFileHistoryDetails((await fileDetailsResult).map(file => file.fileName))
                }
                else if(operationSummary.includes("Edit State"))
                {
                    const oldState  = jobHistoryData.additionalData[0].oldValue;
                    const newState  = jobHistoryData.additionalData[0].newValue;
                    setFileHistoryDetails(`State change from ${oldState} to ${newState}`)
                }
                else 
                {
                    setFileHistoryDetails(null)
                }
            }
        }
        const result = getData().catch(console.error)

    }, [data])
    
    return historyDetails === null? <></>:
        <Container>
                <Row>
                    <Col><strong className="d-flex align-items-center">Application:</strong></Col>
                    <Col xs={7}><div style={{textAlign:"left"}}>{historyDetails.jobHistoryData.application.name}</div></Col>
                </Row>
                <hr/>
                <Row>
                    <Col><strong className="d-flex align-items-center">Created:</strong></Col>
                    <Col xs={7}><div style={{textAlign:"left"}}>{dateTimeFormatter({value: historyDetails?.jobHistoryData.created})}</div></Col>
                </Row>
                <Row>
                    <Col><strong className="d-flex align-items-center">Details:</strong></Col>
                    <Col xs={7}>
                        <div style={{textAlign:"left"}}>{historyDetails?.jobHistoryData.operationSummary}</div>        
                        {
                            fileHistoryDetails !== null?
                            <Col xs={7}><div style={{textAlign:"left"}}>{fileHistoryDetails}</div></Col>:<></>
                        }
                    </Col>
                </Row>
        </Container>
}